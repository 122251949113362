import React, {useState} from 'react';
import {AccordionContainer, AccordionContent} from "../components/Accordion";

const Accordion = ({questions}) => {

    const [active, setActive] = useState();
    const handleClick = (id) => {
        setActive(id === active ? null : id)
    }
    return (
        <AccordionContainer>
            {
                questions.map(question => {
                    let isActive = active === question.id;
                    const {
                        id,
                        questionTitle,
                        control,
                        response,
                        answers,
                        link,
                        linkText,
                        response_b
                    } = question;
                    const data = {
                        'response' : response,
                        'answers': answers ?? [],
                        'link': link ?? '',
                        'linkText' : linkText ?? '',
                        'responseB': response_b ?? ''
                    };
                    return (
                        <AccordionContent
                            key={`question-${id}`}
                            onClick={() => handleClick(id)}
                            itemId={id}
                            isActive={isActive}
                            id={id}
                            questionTitle={questionTitle}
                            control={control}
                            data={data}
                        />
                    )
                })
            }

        </AccordionContainer>
    );
};

export default Accordion;
