import React from 'react';
import styled from "styled-components";

const Inner = styled.p`
  position: absolute;
  padding: 1rem;
  color: var(--gray-primary);

`;
const Question = (props) => {
    const {data,id} = props;

    return (
        <Inner id={id}>
            {data.response}
        </Inner>
    );
};

export default Question;
