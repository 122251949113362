import React from 'react';
import styled from "styled-components";

const WrapperLink = styled.p`
  position: absolute;
  padding: 1rem;
  color: var(--gray-primary);

`;
const LinkStyled = styled.a`
  color: var(--gray-primary);
`
const QuestionLink = (props) => {
    const {response, link,linkText, responseB} = props.data;
    return (
        <WrapperLink id={props.id}>
            {response} {" "}<LinkStyled href={link}  target="_blank"> {linkText}</LinkStyled>
            {" "}
            {responseB}
        </WrapperLink>
    );
};

export default QuestionLink;
