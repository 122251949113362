import styled from "styled-components";

const ListWrapper = styled.div`
  position: absolute;
  padding: 1rem;
  color: var(--gray-primary);

`;
const UlWrapper =  styled.ul`
  list-style: none;
`;
const LiStyled = styled.li`
  padding-top: 1rem;
`;
const QuestionList = (props) => {
    const {answers, response} = props.data;
    return (
        <ListWrapper id={props.id}>
            <p>{response}</p>
            <UlWrapper>
                {
                    answers.map(answer =>{
                        return(
                            <LiStyled>{answer}</LiStyled>
                        )
                    })
                }

            </UlWrapper>
        </ListWrapper>
    );
};

export default QuestionList;
