import './App.css';
import styled from "styled-components";
import Accordion from "./views/Accordion";
import {questions} from "./utils/questions";
import Footer from "./components/Footer";

const Wrapper = styled.div`
  padding: 11rem 2rem;
  font: 600 1.6rem/2rem var(--font-Base);
  @media (min-width: 995px) {
    padding: 7.2rem;
  }
`;

const H1 = styled.h1`
  color: var(--red-secondary);
  font: 700 6.4rem/7.7rem var(--font-Base);

  @media (min-width: 995px) {
    padding: 3rem;
    font: 700 4.8rem/5rem var(--font-Base);
  }
`;

const H2 = styled.h2`
  color: var(--black-primary);
  font: 600 2.4rem/2.9rem var(--font-Base);

  @media (min-width: 995px) {
    padding: 3rem;
    font: 600 2rem/2rem var(--font-Base);
  }
`;
function App() {
  return (
    <Wrapper className="App" style={{
        boxSizing: 'border-box',
        overflow: 'auto',
        height: "100%"}}>
        <H1>FAQ’S</H1>
        <H2>Bienvenido a la sección de Preguntas Frecuentes</H2>
        <Accordion questions={questions}/>
        <Footer/>
    </Wrapper>
  );
}

export default App;
