import styled from "styled-components";
import ArrowOpenIcon from "../../assets/icons/arrowClose.svg";

const StyledArrowDown = styled.div`
  display: flex;
  align-items: center;
`;
export default function ArrowOpen(props) {
    return (
        <StyledArrowDown {...props}>
            <img
                className="up"
                src={ArrowOpenIcon}
                alt="arrow"
            />
        </StyledArrowDown>
    );
}
