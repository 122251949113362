import React from 'react';
import styled from "styled-components";
const FooterWrapper = styled.footer`
  font: 600 2rem/2rem var(--font-Base);
  color:var(--black-primary);
  margin-top: 3.4rem;
  a{
    color: var(--red-secondary);
    text-decoration: underline;
  }
  @media (min-width: 995px) {
    margin-top: 2rem;
  }
`;
const Footer = () => {
    return (
        <FooterWrapper>
            Puedes Encontrar TyC en {" "}
            <a href="https://www.migranjugada.com" target="_blank" rel="noopener noreferrer">www.migranjugada.com</a>
        </FooterWrapper>
    );
};

export default Footer;
