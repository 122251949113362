export const questions = [
    {
        id: 1,
        control: 'isList',
        questionTitle: "¿Cuántos puntos extra obtengo con la captura de cada estampita digital?",
        response: "Podrás obtener 80 puntos extra en total, tendremos 2 estampitas diferentes:",
        answers: [
            'Lionel Messi 40 pts.',
            'Lays® 40 pts.',
            ' '
        ]
    },
    {
        id: 2,
        control: 'isText',
        questionTitle: "¿Puedo capturar más de una a la vez?",
        response: "Puedes capturar una al día (Una estampita de Lionel y otra de Lays®)."
    },
    {
        id: 3,
        control: 'isText',
        questionTitle: "¿Hasta qué fecha puedo capturarlos todos?",
        response: "Las estampitas digitales estarán disponibles para ser capturadas del 13 de marzo al 21 de abril de 2023."
    },
    {
        id: 4,
        control: 'isText',
        questionTitle: "¿Todas las estampitas estarán disponibles al mismo tiempo?",
        response: "Iremos liberando una semanalmente, pero no temas, todas las que no hayan sido ya capturadas quedan disponibles para que tú las atrapes hasta el 21 de abril."
    },
    {
        id: 5,
        control: 'isText',
        questionTitle: "¿Para qué sirven los puntos que gano con las estampitas digitales?",
        response: "Al registrar los códigos que vienen dentro de cada una, sumas puntos extra de la promoción Sin Lays® no hay partido con los que participas para ganar recompensas."
    },
    {
        id: 6,
        control: 'isText',
        questionTitle: "¿Tengo que acercarme físicamente al lugar señalado en el mapa para capturarlas?",
        response: "Sí, para poder realizar la captura tienes que encontrarte a una distancia de menos de 3 metros a las estampitas."
    },
    {
        id: 7,
        control: 'isLink',
        questionTitle: "¿Cuánto tiempo tengo para canjear mis códigos por puntos?",
        response: "Tienes hasta el 21 de abril para poderlos canjear en",
        link: "https://www.migranjugada.com/",
        linkText: "https://www.migranjugada.com/",
    },
    {
        id: 8,
        control: 'isLink',
        questionTitle: "¿Dónde se guardan las estampitas que ya capturé?",
        response: "Dentro de",
        link: "https://wallet.vatom.com/b/layslatam",
        linkText: "https://wallet.vatom.com/b/layslatam ",
        response_b: "podrás ver las estampitas que ya hayas capturado.",
    },
    {
        id: 9,
        control: 'isText',
        questionTitle: "¿Por qué necesito dar permiso para compartir mi ubicación?",
        response: "Es necesario para poder enseñarte las estampitas digitales más cercanas a ti."
    },
    {
        id: 10,
        control: 'isList',
        questionTitle: "¿Por qué a veces no puedo capturar una?",
        response: "Puede ser por una de las siguientes razones:",
        answers: [
            'Ya la habías capturado',
            'Ya capturaste otra estampita el mismo día',
            'Tienes que acercarte más',
            'Tienes problemas de conexión a internet'
        ]
    },
     {
         id: 11,
         control: 'isLink',
         questionTitle: "¿Dónde puedo canjear mi código para obtener los puntos adicionales?",
         response: "Ya teniendo tu estampita tienes que ir a",
         link: "https://www.migranjugada.com/",
         linkText: "https://www.migranjugada.com",
         response_b: "y dentro de “Registra códigos” podrás canjear tu código y recibir tus puntos extra.",
     },

]
