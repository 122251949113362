import QuestionList from "./QuestionList";
import QuestionLink from "./QuestionLink";
import Question from "./Question";

const QuestionControl = ({control, ...rest}) => {

    switch (control){
        case 'isText':
            return <Question {...rest}/>
        case 'isList':
            return <QuestionList {...rest}/>
        case 'isLink':
            return <QuestionLink {...rest} />
        default:
            return null
    }
};

export default QuestionControl;
