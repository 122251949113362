import styled from "styled-components";
import QuestionControl from "./QuestionControl";
import ArrowOpen from "./ui/ArrowOpen";

const AccordionContainer = styled.div`
  width: 100%;
  color: var(--gray-primary);
  
`;

const AccordionWrapper = styled.div`
  padding: 2rem;
  background-color: var(--white);
  margin: 1.6rem;
  @media (min-width: 995px) {
    margin: 2.4rem;
  }
` ;
const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
  align-items: center;
  width: 100%;
  height: 4rem;
  padding: 0 1rem;
  background: var(--white);
  color: var(--red-secondary);
  cursor: pointer;
  font: 700 2.8rem/3.4rem var(--font-Base);
  margin-top: 1rem;

`;
const HeaderIcon = styled.span`
  transform: rotate(${props => props.isActive ? 90 : 0}deg);
  transition: all 0.2s;
`;
const Content = styled.div`
  position: relative;
  overflow: hidden;
  height: ${props => {
    const inner = document.getElementById(props.itemId);
    return `${props.isActive && inner ? inner.clientHeight : 0}px`;
  }};
  width: 100%;  
  transition: height 0.35s;
  color: var(--gray-primary);
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-top: solid 1px var(--gray-secondary);
  text-align: justify;
`;
const HeaderTitle =styled.h1`
  font: 700 2rem/2rem var(--font-Base);
  text-align: left;
  @media (min-width: 995px) {
    font: 700 2rem/3.4rem var(--font-Base);
  }
`;
const AccordionContent = ({onClick,itemId, questionTitle,control, isActive, ...rest }) => {

    return (
        <AccordionWrapper>
            <Header onClick ={onClick}>
                <HeaderIcon isActive={isActive}>
                    <ArrowOpen/>
                </HeaderIcon>
                <HeaderTitle>{questionTitle}</HeaderTitle>
            </Header>

            <Content itemId={itemId} isActive={isActive}>
                <QuestionControl
                    control={control}
                    id={itemId}
                    {...rest}
                />
            </Content>
        </AccordionWrapper>
    )
}

export { AccordionContainer, AccordionContent}
